
import { defineComponent, defineAsyncComponent } from "vue";
import {
  IStatisticsFetch,
  IElement,
  IOrganisationsFetch,
  IElementFetch,
} from "@/interface/statistics";

export default defineComponent({
  data() {
    return {
      loading: true,
      countMembers: 0,
      sex: {
        wooman: 0,
        man: 0,
      },
      geolocation: [] as IElementFetch[],
      income: {
        array: [] as IElement[],
        value: "",
      },
      stuffCount: {
        array: [] as IElement[],
        value: "",
      },
      turnover: {
        array: [] as IElement[],
        value: "",
      },
      age: [] as IElement[],
      organisations: [] as IElement[],
      yearsInBusiness: [
        {
          text: "1-5 лет",
          percent: 0,
        },
        {
          text: "6-10 лет",
          percent: 0,
        },
        {
          text: "10+ лет",
          percent: 0,
        },
      ] as IElement[],
      stages: [] as number[],
    };
  },
  components: {
    CountMembers: defineAsyncComponent(
      () => import("../components/statistics/CountMembers.vue")
    ),
    Sex: defineAsyncComponent(() => import("../components/statistics/Sex.vue")),
    Industry: defineAsyncComponent(
      () => import("../components/statistics/Industry.vue")
    ),
    Geolocation: defineAsyncComponent(
      () => import("../components/statistics/Geolocation.vue")
    ),
    Aggregate: defineAsyncComponent(
      () => import("../components/statistics/Aggregate.vue")
    ),
    AnalyticsVertical: defineAsyncComponent(
      () => import("../components/statistics/AnalyticsVertical.vue")
    ),
    Stages: defineAsyncComponent(
      () => import("../components/statistics/Stages.vue")
    ),
    Loader: defineAsyncComponent(() => import("../components/Loader.vue")),
  },
  async mounted() {
    await this.fetchParticipants();
    await this.fetchOrganisations();

    this.loading = false;
  },
  methods: {
    async fetchParticipants() {
      const fetchApi = await fetch(
        `${process.env.VUE_APP_API_URL}/api/Report/ClubParticipants`,
        {
          method: "POST",
          body: "{ clubIsPayed: 1 }",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json-patch+json",
          },
        }
      );
      const fetchData: IStatisticsFetch = (await fetchApi.json()).Data;
      this.countMembers = fetchData.Count || 0;
      const ageArr = this.mapArray(fetchData.ByAge);
      const defObj = { text: "", percent: 0 };
      this.age = [
        ageArr.find((el) => el.text === "25-30") || defObj,
        ageArr.find((el) => el.text === "31-40") || defObj,
        ageArr.find((el) => el.text === "41-50") || defObj,
        {
          ...(ageArr.find((el) => el.text === "Старше 50") || defObj),
          text: "50-100",
        },
      ];

      this.sex.man =
        fetchData.BySex.find((el) => el.TextView === "Мужской")?.Percentage ||
        0;
      this.sex.wooman =
        fetchData.BySex.find((el) => el.TextView === "Женский")?.Percentage ||
        0;

      this.geolocation = fetchData.ByCity;

      this.income.value = fetchData.ByIncomeMedian?.replaceAll(" ", "") || "";
      this.income.array = this.mapArray(fetchData.ByIncome, true);

      this.stuffCount.value =
        fetchData.ByStuffCountMedian?.replaceAll(" ", "") || "";
      this.stuffCount.array = this.mapArray(fetchData.ByStuffCount, true);

      this.turnover.value =
        fetchData.ByTurnoverMedian?.replaceAll(" ", "") || "";
      this.turnover.array = this.mapArray(fetchData.ByTurnover, true);

      this.stages =
        fetchData.ByBusinessLifecycle?.map((el) => el.Percentage) || [];

      const yearsArr = fetchData.ByYearsInBusiness.filter(
        (el) => el.Percentage >= 0
      );
      const summaCount = yearsArr.reduce(
        (sum: number, el: IElementFetch) => sum + (el.Count || 0),
        0
      );
      yearsArr.forEach((el) => {
        this.yearsInBusiness[
          el.Number <= 5 ? 0 : el.Number <= 10 ? 1 : 2
        ].percent += el.Count || 0;
      });

      if (summaCount !== 0) {
        this.yearsInBusiness.forEach(
          (el) => (el.percent = (el.percent * 100) / summaCount)
        );
      } else {
        this.yearsInBusiness.forEach((el) => (el.percent = 0));
      }
    },
    async fetchOrganisations() {
      const fetchApi = await fetch(
        `${process.env.VUE_APP_API_URL}/api/Report/ClubOrganisations`,
        {
          method: "POST",
          body: "{ clubIsPayed: 1 }",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json-patch+json",
          },
        }
      );
      const fetchData: IOrganisationsFetch = (await fetchApi.json()).Data;
      this.organisations = this.mapArray(fetchData.ByActivityKind, true).sort(
        (a, b) => {
          return a.percent > b.percent ? -1 : a.percent < b.percent ? 1 : 0;
        }
      );
    },
    mapArray(arr: IElementFetch[], isFilterPercent = false): IElement[] {
      if (!arr) return [];
      const result = arr.map((el) => ({
        text: el.TextView,
        percent: el.Percentage,
      }));

      if (isFilterPercent) {
        return result.filter(
          (el) => el.percent >= 0 && el.text !== "0. Еще не заполнено"
        );
      } else {
        return result;
      }
    },
  },
});
